import React from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import NavBar from "./navbar.control";

/* admin */

import AdminEmployeesPage from "./admin/employees/employees.page";
import AdminEmployeePage from "./admin/employees/employee.page";
import AdminEmployeeReportsPage from "./admin/employees/employeeReports.page";

import AdminProductGroupsPage from "./admin/products/productGroups.page";
import AdminAllProductsPage from "./admin/products/products.page";
import AdminProductGroupPage from "./admin/products/productGroup.page";
import AdminProductGroupProductsPage from "./admin/products/productGroupProducts.page";
import AdminProductPage from "./admin/products/product.page";
import AdminProductInvoicesPage from "./admin/products/productInvoices.page";
import AdminProductInvoicePage from "./admin/products/productInvoice.page";
import AdminProductCustomersPage from "./admin/products/productCustomers.page";
import AdminProductReportsPage from "./admin/products/productReports.page";
import AdminProductReviewsPage from "./admin/products/productReviews.page";
import AdminProductReviewPage from "./admin/products/productReview.page";

/* employee */

import AdminEmployeeProfilePage from "./admin/employee/profile/profile.page";

import AdminEmployeeReportsPage2 from "./admin/employee/reports/reports.page";

import AdminEmployeeCalenderEventsPage from "./admin/employee/calenderEvents/calenderEvents.page";
import AdminEmployeeCalenderEventPage from "./admin/employee/calenderEvents/calenderEvent.page";

import AdminEmployeeReportEventsPage from "./admin/employee/reportEvents/reportEvents.page";
import AdminEmployeeReportEventPage from "./admin/employee/reportEvents/reportEvent.page";

import AdminEmployeeInvoicesPage from "./admin/employee/invoices/invoices.page";
import AdminEmployeeInvoicePage from "./admin/employee/invoices/invoice.page";

import AdminEmployeeOrdersPage from "./admin/employee/orders/orders.page";
import AdminEmployeeOrderPage from "./admin/employee/orders/order.page";
import AdminEmployeeNewOrderPage from "./admin/employee/orders/newOrder.page";

/* frontpage */

import AdminFrontpageVideosPage from "./admin/frontpage/videos/videos.page";
import AdminFrontpageVideoPage from "./admin/frontpage/videos/video.page";

import AdminFrontpageReferencePage from "./admin/frontpage/references/reference.page";
import AdminFrontpageReferencesPage from "./admin/frontpage/references/references.page";

import AdminFrontpageAboutPage from "./admin/frontpage/about/about.page";

import AdminFrontpageNewsletterSubscriber from "./admin/frontpage/newsletter/subscriber.page";
import AdminFrontpageNewsletterSubscribers from "./admin/frontpage/newsletter/subscribers.page";

import AdminFrontpageFocusProductsPage from "./admin/frontpage/focusProducts/focusProducts.page";
import AdminFrontpageFocusProductPage from "./admin/frontpage/focusProducts/focusProduct.page";

import AdminFrontpageAnnouncementPage from "./admin/frontpage/announcements/announcement.page";
import AdminFrontpageAnnouncementsPage from "./admin/frontpage/announcements/announcements.page";

import AdminFrontpageGeneralPage from "./admin/frontpage/general/frontpage.page";
import AdminFrontpageBannersPage from "./admin/frontpage/banners/banners.page";
import AdminFrontpageBannerPage from "./admin/frontpage/banners/banner.page";

import AdminCustomerPage from "./admin/customers/customer.page";
import AdminCustomerInfoPage from "./admin/customers/customerInfo.page";
import AdminCustomerReportsPage from "./admin/customers/customerReports.page";
import AdminCustomersPage from "./admin/customers/customers.page";
import AdminCustomerNotesPage from "./admin/customers/customerNotes.page";
import AdminCustomerNotePage from "./admin/customers/customerNote.page";
import AdminCustomerInvoicePage from "./admin/customers/customerInvoice.page";
import AdminCustomerInvoicesPage from "./admin/customers/customerInvoices.page";
import AdminCustomerCalenderPage from "./admin/customers/customerCalender.page";
import AdminCustomerInvoiceLinesPage from "./admin/customers/customerInvoiceLines.page";

import AdminMoreRolesPage from "./admin/more/roles/roles.page";
import AdminMoreRolePage from "./admin/more/roles/role.page";

/* more */

import AdminEmailsSendPage from "./admin/more/emails/send.page";

import AdminOrdersPage from "./admin/more/orders/orders.page";
import AdminEmployeeOrderPage2 from "./admin/more/orders/employeeOrder.page";
import AdminCustomerOrderPage2 from "./admin/more/orders/customerOrder.page";

import AdminMoreSettingsPage from "./admin/more/settings/settings.page";

import AdminMoreJobsPage from "./admin/more/jobs/jobs.page";
import AdminMoreJobPage from "./admin/more/jobs/job.page";

import AdminMoreQRCodesPage from "./admin/more/qrcodes/qrcodes.page";
import AdminMoreQRCodePage from "./admin/more/qrcodes/qrcode.page";

import AdminMoreEmailTemplatesPage from "./admin/more/emailTemplates/emailTemplates.page";
import AdminMoreEmailTemplatePage from "./admin/more/emailTemplates/emailTemplate.page";

/* customer */
import CustomerCalenderEventsPage from "./customer/calenderEvents/calenderEvents.page";
import CustomerCalenderEventPage from "./customer/calenderEvents/calenderEvent.page";

import CustomerInvoiceLinesPage from "./customer/customerInvoiceLines/customerInvoiceLines.page";

import CustomerInvoicesPage from "./customer/invoices/invoices.page";
import CustomerInvoicePage from "./customer/invoices/invoice.page";

import CustomerWelcomePage from "./customer/welcome/welcome.page";

import CustomerProfilePage from "./customer/profile/profile.page";

/* visitor */

import ProductGroupsPage from "./products/productGroups.page";
import ProductGroupPage from "./products/productGroup.page";
import ProductPage from "./products/product.page";

import NewsletterUnsubscribePage from "./newsletter/unsubscribe.page";
import FocusProductsPage from "./focusproducts/focusproducts.page";
import ReferencesPage from "./references/references.page";
import VideosPage from "./videos/videos.page";
import AnnouncementPage from "./announcements/announcement.page";
import AnnouncementsPage from "./announcements/announcements.page";
import LoginPage from "./login/login.page";
import MainPage from "./main/main.page";
import AboutPage from "./about/about.page";
import ContactPage from "./contact/contact.page";
import MeetingPage from "./contact/meeting.page";
import DatasheetPage from "./datasheet/datasheet.page";

import ScrollToTop from "../scrollToTop.feature";

import rootSettings from "../../utils/settings";
import { useAppState } from "../contexts/appState.context";
import { useMessage } from "../contexts/message.context";
import MetadataControl from "../controls/metadata.control";

const UserProtectedAreaFeature = ({ type, title }) => {
  const location = useLocation();
  const appStateCtx = useAppState();
  const messageCtx = useMessage();

  if (!appStateCtx.user) {
    messageCtx.showWarning(
      "Du er ikke logget ind. Log venligst ind for at komme til siden"
    );

    return (
      <Navigate
        replace
        to={location.pathname ? `/logind?url=${location.pathname}` : "/logind"}
      />
    );
  }

  if (appStateCtx.user.type !== type) {
    messageCtx.showWarning(
      "Du har ikke adgang til siden. Log venligst ind som korrekt person."
    );

    return (
      <Navigate
        replace
        to={location.pathname ? `/logind?url=${location.pathname}` : "/logind"}
      />
    );
  }

  return (
    <>
      <MetadataControl title={title} />
      <Outlet />
    </>
  );
};

const AppNav = () => {
  return (
    <div>
      <NavBar />

      {/* <div style={{ paddingTop: 106 }}> */}
      <div>
        <ScrollToTop>
          <Routes>
            <Route path="/">
              <Route index element={<MainPage />} />

              <Route path="om" element={<AboutPage />} />

              <Route path="videoer" element={<VideosPage />} />

              <Route path="produkterifokus" element={<FocusProductsPage />} />

              <Route path="referencer" element={<ReferencesPage />} />

              <Route path="nyheder">
                <Route index element={<AnnouncementsPage />} />
                <Route path=":announcementId" element={<AnnouncementPage />} />
              </Route>

              <Route path="produktGrupper">
                <Route index element={<ProductGroupsPage />} />
                <Route path=":productGroupName">
                  <Route index element={<ProductGroupPage />} />
                  <Route path=":productName" element={<ProductPage />} />
                </Route>
              </Route>

              <Route path="sikkerhedsdatablade" element={<DatasheetPage />} />

              <Route path="kontakt">
                <Route index element={<ContactPage />} />
                <Route path="email" element={<MeetingPage />} />
              </Route>

              <Route
                path="kunde"
                element={
                  <UserProtectedAreaFeature
                    type="CustomerContact"
                    title="Capp2: Kunde"
                  />
                }
              >
                <Route path="fakturaer">
                  <Route index element={<CustomerInvoicesPage />} />
                  <Route
                    path=":bookedInvoiceNumber"
                    element={<CustomerInvoicePage />}
                  />
                </Route>

                <Route path="profil" element={<CustomerProfilePage />} />

                <Route
                  path="tidligerekoeb"
                  element={<CustomerInvoiceLinesPage />}
                />

                <Route path="møder">
                  <Route index element={<CustomerCalenderEventsPage />} />
                  <Route
                    path=":calenderEventId"
                    element={<CustomerCalenderEventPage />}
                  />
                </Route>
                <Route path="velkommen" element={<CustomerWelcomePage />} />
              </Route>

              <Route
                path="nyhedsbrev/afmeld/:id"
                element={<NewsletterUnsubscribePage />}
              />

              <Route path="logind" element={<LoginPage />} />

              <Route
                path="admin"
                element={
                  <UserProtectedAreaFeature
                    type="Employee"
                    title="Capp2: Admin"
                  />
                }
              >
                <Route path="qrkoder">
                  <Route index element={<AdminMoreQRCodesPage />} />
                  <Route path="ny" element={<AdminMoreQRCodePage />} />
                  <Route path=":qrCodeId" element={<AdminMoreQRCodePage />} />
                </Route>

                <Route path="roller">
                  <Route index element={<AdminMoreRolesPage />} />
                  <Route path="ny" element={<AdminMoreRolePage />} />
                  <Route path=":roleId" element={<AdminMoreRolePage />} />
                </Route>

                <Route path="fokusprodukter">
                  <Route index element={<AdminFrontpageFocusProductsPage />} />
                  <Route
                    path="ny"
                    element={<AdminFrontpageFocusProductPage />}
                  />
                  <Route path=":productGroupName">
                    <Route
                      path=":productName"
                      element={<AdminFrontpageFocusProductPage />}
                    />
                  </Route>
                </Route>

                <Route path="omos" element={<AdminFrontpageAboutPage />} />

                <Route path="medarbejdere">
                  <Route index element={<AdminEmployeesPage />} />
                  <Route path=":employeeNumber">
                    <Route index element={<AdminEmployeePage />} />
                    <Route
                      path="rapporter"
                      element={<AdminEmployeeReportsPage />}
                    />
                  </Route>
                </Route>

                <Route path="ordrer">
                  <Route index element={<AdminOrdersPage />} />
                  <Route
                    path="kunde/:customerOrderId"
                    element={<AdminCustomerOrderPage2 />}
                  />
                  <Route
                    path="medarbejder/:employeeOrderId"
                    element={<AdminEmployeeOrderPage2 />}
                  />
                </Route>

                <Route path="medarbejder">
                  <Route path="kalender">
                    <Route
                      index
                      element={<AdminEmployeeCalenderEventsPage />}
                    />
                    <Route
                      path="ny"
                      element={<AdminEmployeeCalenderEventPage />}
                    />
                    <Route
                      path=":calenderEventId"
                      element={<AdminEmployeeCalenderEventPage />}
                    />
                  </Route>
                  <Route path="fakturaer">
                    <Route index element={<AdminEmployeeInvoicesPage />} />
                    <Route
                      path=":bookedInvoiceNumber"
                      element={<AdminEmployeeInvoicePage />}
                    />
                  </Route>
                  <Route path="ordrer">
                    <Route index element={<AdminEmployeeOrdersPage />} />
                    <Route path="ny" element={<AdminEmployeeNewOrderPage />} />
                    <Route
                      path=":orderId"
                      element={<AdminEmployeeOrderPage />}
                    />
                  </Route>
                  <Route path="registrering">
                    <Route index element={<AdminEmployeeReportEventsPage />} />
                    <Route
                      path="ny"
                      element={<AdminEmployeeReportEventPage />}
                    />
                    <Route
                      path=":reportEventId"
                      element={<AdminEmployeeReportEventPage />}
                    />
                  </Route>
                  <Route
                    path="rapporter"
                    element={<AdminEmployeeReportsPage2 />}
                  />
                  <Route path="profil" element={<AdminEmployeeProfilePage />} />
                </Route>

                <Route path="forside" element={<AdminFrontpageGeneralPage />} />

                <Route path="nyhedsbrev">
                  <Route
                    index
                    element={<AdminFrontpageNewsletterSubscribers />}
                  />
                  <Route
                    path=":subscriberId"
                    element={<AdminFrontpageNewsletterSubscriber />}
                  />
                </Route>

                <Route path="videoer">
                  <Route index element={<AdminFrontpageVideosPage />} />
                  <Route path="ny" element={<AdminFrontpageVideoPage />} />
                  <Route
                    path=":videoId"
                    element={<AdminFrontpageVideoPage />}
                  />
                </Route>

                <Route path="referencer">
                  <Route index element={<AdminFrontpageReferencesPage />} />
                  <Route path="ny" element={<AdminFrontpageReferencePage />} />
                  <Route
                    path=":referenceId"
                    element={<AdminFrontpageReferencePage />}
                  />
                </Route>

                <Route path="nyheder">
                  <Route index element={<AdminFrontpageAnnouncementsPage />} />
                  <Route
                    path="ny"
                    element={<AdminFrontpageAnnouncementPage />}
                  />
                  <Route
                    path=":announcementId"
                    element={<AdminFrontpageAnnouncementPage />}
                  />
                </Route>

                <Route path="banners">
                  <Route index element={<AdminFrontpageBannersPage />} />
                  <Route path="ny" element={<AdminFrontpageBannerPage />} />
                  <Route
                    path=":bannerId"
                    element={<AdminFrontpageBannerPage />}
                  />
                </Route>

                <Route path="kunder">
                  <Route index element={<AdminCustomersPage />} />
                  <Route path=":customerNumber">
                    <Route index element={<AdminCustomerPage />} />
                    <Route path="info" element={<AdminCustomerInfoPage />} />
                    <Route path="noter">
                      <Route index element={<AdminCustomerNotesPage />} />

                      <Route path="ny" element={<AdminCustomerNotePage />} />
                      <Route
                        path=":customerNoteId"
                        element={<AdminCustomerNotePage />}
                      />
                    </Route>
                    <Route path="fakturaer">
                      <Route index element={<AdminCustomerInvoicesPage />} />
                      <Route
                        path=":bookedInvoiceNumber"
                        element={<AdminCustomerInvoicePage />}
                      />
                    </Route>
                    <Route
                      path="fakturalinjer"
                      element={<AdminCustomerInvoiceLinesPage />}
                    ></Route>

                    <Route
                      path="kalender"
                      element={<AdminCustomerCalenderPage />}
                    />
                    <Route
                      path="rapporter"
                      element={<AdminCustomerReportsPage />}
                    />
                  </Route>
                </Route>

                <Route path="emailskabeloner">
                  <Route index element={<AdminMoreEmailTemplatesPage />} />
                  <Route
                    path=":emailTemplateId"
                    element={<AdminMoreEmailTemplatePage />}
                  />
                </Route>

                <Route path="emails">
                  <Route path="send" element={<AdminEmailsSendPage />} />
                </Route>

                <Route
                  path="indstillinger"
                  element={<AdminMoreSettingsPage />}
                />

                <Route path="job">
                  <Route index element={<AdminMoreJobsPage />} />
                  <Route path=":jobId" element={<AdminMoreJobPage />} />
                </Route>

                <Route path="produktGrupper">
                  <Route index element={<AdminProductGroupsPage />} />
                  <Route path=":productGroupName">
                    <Route index element={<AdminProductGroupPage />} />
                    <Route path="produkter">
                      <Route
                        index
                        element={<AdminProductGroupProductsPage />}
                      />
                      <Route path=":productName">
                        <Route index element={<AdminProductPage />} />
                        <Route path="fakturaer">
                          <Route index element={<AdminProductInvoicesPage />} />
                          <Route
                            path=":bookedInvoiceNumber"
                            element={<AdminProductInvoicePage />}
                          />
                        </Route>
                        <Route path="anmeldelser">
                          <Route index element={<AdminProductReviewsPage />} />
                          <Route
                            path="ny"
                            element={<AdminProductReviewPage />}
                          />
                          <Route
                            path=":productReviewId"
                            element={<AdminProductReviewPage />}
                          />
                        </Route>
                        <Route
                          path="kunder"
                          element={<AdminProductCustomersPage />}
                        />
                        <Route
                          path="rapporter"
                          element={<AdminProductReportsPage />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>

                <Route path="produkter" element={<AdminAllProductsPage />} />

                <Route
                  element={
                    <Navigate replace to="/admin/medarbejder/kalender" />
                  }
                />
              </Route>
            </Route>
          </Routes>
        </ScrollToTop>
      </div>

      <section className="backgroundcolor-blue p-5">
        <div className="row">
          <div className="text-center color-white btn-action">
            {`${rootSettings.company.name}, ${rootSettings.company.street}, ${rootSettings.company.zip} ${rootSettings.company.city}, ${rootSettings.company.countryCode}, CVR: ${rootSettings.company.cvr}`}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppNav;
