import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faSearch,
  faUser,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import logo from "../../assets/logo.svg";

import rootSettings from "../../utils/settings";
import { useAppState } from "../contexts/appState.context";
import { useCart } from "../contexts/cart.context";
import { useSearch } from "../contexts/search.context";
import { useMessage } from "../contexts/message.context";
import { useSettings } from "../contexts/settings.context";
import { useFavorites } from "../contexts/favorites.context";

const NavBarControl = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const messageCtx = useMessage();
  const settingsCtx = useSettings();
  const cartCtx = useCart();
  const searchCtx = useSearch();
  const favoritesCtx = useFavorites();

  const navButton = useRef(null);
  const linksContainerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const leftLinks = [
    // public
    {
      name: "Velkommen",
      url: "/",
      hasPermission: (user) =>
        user?.permissionIds?.includes("menu.welcome.view"),
      isSelected: (url) => url === "/",
    },
    {
      name: "Produkter",
      url: "/produktGrupper",
      hasPermission: (user) =>
        user?.permissionIds?.includes("menu.products.view"),
      isSelected: (url) => url.startsWith("/produktGrupper"),
    },
    {
      name: "Sikkerhedsdatablade",
      url: "/sikkerhedsdatablade",
      hasPermission: (user) =>
        user?.permissionIds?.includes("menu.datasheets.view"),
      isSelected: (url) => url === "/sikkerhedsdatablade",
    },
    {
      name: "Kontakt",
      url: "/kontakt",
      hasPermission: (user) =>
        user?.permissionIds?.includes("menu.contact.view"),
      isSelected: (url) => url === "/kontakt",
    },
    {
      name: "Om os",
      url: "/om",
      hasPermission: (user) => user?.permissionIds?.includes("menu.about.view"),
      isSelected: (url) => url === "/om",
    },

    // admin
    {
      name: "Produkter",
      url: "/admin/produkter",
      hasPermission: (user) =>
        user?.permissionIds?.includes("admin.products.view"),
      isSelected: (url) => url.startsWith("/admin/produkter"),
    },
    {
      name: "Kunder",
      url: "/admin/kunder",
      hasPermission: (user) =>
        user?.permissionIds?.includes("admin.customers.view"),
      isSelected: (url) => url.startsWith("/admin/kunder"),
    },
    {
      name: "Medarbejdere",
      url: "/admin/medarbejdere",
      hasPermission: (user) =>
        user?.permissionIds?.includes("admin.employees.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejdere"),
    },

    {
      name: "Forside",
      items: [
        {
          name: "Banners",
          url: "/admin/banners",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.banners.view"),
          isSelected: (url) => url.startsWith("/admin/banner"),
        },
        {
          name: "Generalt",
          url: "/admin/forside",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.general.view"),
          isSelected: (url) => url.startsWith("/admin/forside"),
        },
        {
          name: "Nyheder",
          url: "/admin/nyheder",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.announcements.view"),
          isSelected: (url) => url.startsWith("/admin/nyheder"),
        },
        {
          name: "Nyhedsbrev abonnementer",
          url: "/admin/nyhedsbrev",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.newsletter.view"),
          isSelected: (url) => url.startsWith("/admin/nyhedsbrev"),
        },
        {
          name: "Om os",
          url: "/admin/omos",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.aboutus.view"),
          isSelected: (url) => url.startsWith("/admin/omos"),
        },
        {
          name: "Produkter i fokus",
          url: "/admin/fokusprodukter",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.focusproducts.view"),
          isSelected: (url) => url.startsWith("/admin/fokusprodukter"),
        },
        {
          name: "Referencer",
          url: "/admin/referencer",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.references.view"),
          isSelected: (url) => url.startsWith("/admin/referencer"),
        },
        {
          name: "Videoer",
          url: "/admin/videoer",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.videos.view"),
          isSelected: (url) => url.startsWith("/admin/videoer"),
        },
      ],
    },
    {
      name: "Mere",
      items: [
        {
          name: "Send email",
          url: "/admin/emails/send",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.emails.view"),
          isSelected: (url) => url === "/admin/emails/send",
        },
        {
          name: "Email skabeloner",
          url: "/admin/emailskabeloner",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.emailTemplates.view"),
          isSelected: (url) => url.startsWith("/admin/emailskabeloner"),
        },
        {
          name: "Indstillinger",
          url: "/admin/indstillinger",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.settings.view"),
          isSelected: (url) => url.startsWith("/admin/indstillinger"),
        },
        {
          name: "Ordrer",
          url: "/admin/ordrer",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.orders.view"),
          isSelected: (url) => url.startsWith("/admin/ordrer"),
        },
        {
          name: "QR koder",
          url: "/admin/qrkoder",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.qrcodes.view"),
          isSelected: (url) => url.startsWith("/admin/qrkoder"),
        },
        {
          name: "Job",
          url: "/admin/job",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.jobs.view"),
          isSelected: (url) => url.startsWith("/admin/job"),
        },
        {
          name: "Roller",
          url: "/admin/roller",
          hasPermission: (user) =>
            user?.permissionIds?.includes("admin.roles.view"),
          isSelected: (url) => url.startsWith("/admin/roller"),
        },
      ],
    },
  ];

  const customerLinks = [
    // customer
    {
      name: "Velkommen",
      url: "/kunde/velkommen",
      hasPermission: (user) =>
        user?.permissionIds?.includes("customer.welcome.view"),
      isSelected: (url) => url.startsWith("/kunde/velkommen"),
    },
    {
      name: "Profil",
      url: "/kunde/profil",
      hasPermission: (user) =>
        user?.permissionIds?.includes("customer.profile.view"),
      isSelected: (url) => url.startsWith("/kunde/profil"),
    },
    {
      name: "Fakturaer",
      url: "/kunde/fakturaer",
      hasPermission: (user) =>
        user?.permissionIds?.includes("customer.invoices.view"),
      isSelected: (url) => url.startsWith("/kunde/fakturaer"),
    },
    {
      name: "Møder",
      url: "/kunde/møder",
      hasPermission: (user) =>
        user?.permissionIds?.includes("customer.meetings.view"),
      isSelected: (url) => url.startsWith("/kunde/m"),
    },
    {
      name: "Tidligere købt",
      url: "/kunde/tidligerekoeb",
      hasPermission: (user) =>
        user?.permissionIds?.includes("customer.previouslybought.view"),
      isSelected: (url) => url.startsWith("/kunde/tidligerekoeb"),
    },
    {
      name: "Log ud",
      onClick: async () => {
        navigate("/");
        messageCtx.showSuccess("Bruger logget ud");
        await appStateCtx.logout();
      },
      hasPermission: (user) => user?.type === "CustomerContact",
      isSelected: (url) => false,
    },
  ];

  const employeeLinks = [
    {
      name: "Profil",
      url: "/admin/medarbejder/profil",
      hasPermission: (user) =>
        user?.permissionIds?.includes("employee.profile.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejder/profil"),
    },
    {
      name: "Kalender",
      url: "/admin/medarbejder/kalender",
      hasPermission: (user) =>
        user?.permissionIds?.includes("employee.calender.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejder/kalender"),
    },
    {
      name: "Registreringer",
      url: "/admin/medarbejder/registrering",
      hasPermission: (user) =>
        user?.permissionIds?.includes("employee.registration.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejder/registrering"),
    },
    {
      name: "Fakturaer",
      url: "/admin/medarbejder/fakturaer",
      hasPermission: (user) =>
        user?.permissionIds?.includes("employee.invoices.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejder/fakturaer"),
    },
    {
      name: "Ordrer",
      url: "/admin/medarbejder/ordrer",
      hasPermission: (user) =>
        user?.permissionIds?.includes("employee.orders.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejder/ordrer"),
    },
    {
      name: "Rapporter",
      url: "/admin/medarbejder/rapporter",
      hasPermission: (user) =>
        user?.permissionIds?.includes("employee.reports.view"),
      isSelected: (url) => url.startsWith("/admin/medarbejder/rapporter"),
    },
    {
      name: "Log ud",
      onClick: async () => {
        navigate("/");
        messageCtx.showSuccess("Bruger logget ud");
        await appStateCtx.logout();
      },
      hasPermission: (user) => user?.type === "Employee",
      isSelected: (url) => false,
    },
  ];

  const handleFavoritesClicked = () => {
    setIsExpanded(false);
    favoritesCtx.show(true);
  };

  const handleCartClicked = () => {
    setIsExpanded(false);
    cartCtx.show(true);
  };

  const handleLoginClicked = () => {
    setIsExpanded(false);
    navigate("./logind");
  };

  const handleNavItemClicked = (link) => {
    setIsExpanded(false);
    if (link.onClick) {
      link.onClick();
    } else {
      if (window.location.pathname === link.url) {
        window.scrollTo(0, 0);
      } else {
        navigate(link.url);
      }
    }
  };

  const handleSearchClicked = () => {
    setIsExpanded(false);
    searchCtx.show(true);
  };

  const renderLink = (link, isDropDown) => {
    if (link.items) {
      if (link.items.every((l) => !l.hasPermission(appStateCtx.user)))
        return null;
      const selected = link.items.some((l) =>
        l.isSelected(window.location.pathname)
      );

      return (
        <NavDropdown
          key={link.name}
          title={link.name}
          className={selected ? "activeDropDown" : ""}
        >
          {link.items.map((l) => renderLink(l, true))}
        </NavDropdown>
      );
    } else {
      if (!link.hasPermission(appStateCtx.user)) return null;

      if (isDropDown) {
        return (
          <NavDropdown.Item
            key={link.name}
            className={
              "px-2 " +
              (link.isSelected && link.isSelected(window.location.pathname)
                ? "active"
                : "")
            }
            onClick={() => handleNavItemClicked(link)}
          >
            {link.name}
          </NavDropdown.Item>
        );
      } else {
        return (
          <Nav.Link
            key={link.name}
            className={
              link.isSelected && link.isSelected(window.location.pathname)
                ? "active"
                : ""
            }
            onClick={() => handleNavItemClicked(link)}
          >
            {link.name}
          </Nav.Link>
        );
      }
    }
  };

  return (
    <section>
      <Navbar
        bg="light"
        expand="lg"
        // fixed={"top"}
        className="header"
        onToggle={() => setIsExpanded(!isExpanded)}
        expanded={isExpanded}
      >
        <div className="container">
          <Navbar.Brand>
            <Link className="navbar-brand" to="/">
              <img src={logo} style={{ maxHeight: "80px" }} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" ref={navButton} />
          <Navbar.Collapse id="basic-navbar-nav" ref={linksContainerRef}>
            <Nav className="me-auto"></Nav>
            {/* <Nav style={{ alignItems: "center" }}> */}
            <Nav>
              {leftLinks.map((l) => renderLink(l, false))}

              {appStateCtx.user?.permissionIds?.includes(
                "customer.favorites.view"
              ) && (
                <Nav.Link
                  onClick={handleFavoritesClicked}
                  className="clickable"
                >
                  <FontAwesomeIcon icon={faHeart} size="xl" />
                  <span
                    className="position-absolute translate-middle badge rounded-pill bg-danger"
                    style={{
                      padding: "0.20em 0.45em 0.20em 0.45em",
                    }}
                  >
                    {favoritesCtx.favorites.length}
                  </span>
                </Nav.Link>
              )}

              {settingsCtx.settings?.cartState.startsWith("Ja") &&
                appStateCtx.user?.permissionIds?.includes(
                  "customer.cart.view"
                ) && (
                  <Nav.Link onClick={handleCartClicked} className="clickable">
                    <FontAwesomeIcon icon={faCartShopping} size="xl" />
                    <span
                      className="position-absolute translate-middle badge rounded-pill bg-danger"
                      style={{
                        padding: "0.20em 0.45em 0.20em 0.45em",
                      }}
                    >
                      {cartCtx.cart.lines.reduce((a, b) => a + b.amount, 0)}
                    </span>
                  </Nav.Link>
                )}

              {(!appStateCtx.user ||
                appStateCtx.user?.permissionIds?.includes(
                  "customer.search.view"
                )) && (
                <Nav.Link onClick={handleSearchClicked} className="clickable">
                  <FontAwesomeIcon
                    icon={faSearch}
                    size="xl"
                    color={rootSettings.colors.primary}
                  />
                </Nav.Link>
              )}
              {employeeLinks.some((l) => l.hasPermission(appStateCtx.user)) && (
                <NavDropdown
                  align={"end"}
                  title={<FontAwesomeIcon icon={faUser} size="xl" />}
                  className={employeeLinks.some((l) =>
                    l.isSelected(window.location.pathname)
                      ? "activeDropDown"
                      : ""
                  )}
                >
                  {employeeLinks.map((link) => renderLink(link, true))}
                </NavDropdown>
              )}
              {customerLinks.some((l) => l.hasPermission(appStateCtx.user)) && (
                <NavDropdown
                  align={"end"}
                  title={<FontAwesomeIcon icon={faUser} size="xl" />}
                  className={customerLinks.some((l) =>
                    l.isSelected(window.location.pathname)
                      ? "activeDropDown"
                      : ""
                  )}
                >
                  {customerLinks.map((link) => renderLink(link, true))}
                </NavDropdown>
              )}
              {appStateCtx.user?.type === "Visitor" && (
                <div className="navbar-buttons">
                  <button
                    onClick={handleLoginClicked}
                    className="btn btn-secondary display-4 display-bold ms-3"
                  >
                    Log ind
                  </button>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </section>
  );
};
export default NavBarControl;
