import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import validateUtil from "../../../../../utils/validate.util";

import { useException } from "../../../../contexts/exception.context";
import { useAppState } from "../../../../contexts/appState.context";
import { useMessage } from "../../../../contexts/message.context";
import HtmlInput, { sanitizeHtml } from "../../../../controls/html.input";
import TextInput from "../../../../controls/text.input";
import SelectInput from "../../../../controls/select.input";
import FileInput from "../../../../controls/file.input";
import TableControl from "../../../../controls/table.control";
import RadiosInput from "../../../../controls/radios.input";

const SendPage = () => {
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [emailLists, setEmailLists] = useState([]);

  const [showLoadModal, setShowLoadModal] = useState(false);
  const [savedEmails, setSavedEmails] = useState([]);

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [saveAsNew, setSaveAsNew] = useState(true);
  const [newName, setNewName] = useState("");

  const [email, setEmail] = useState();

  const refresh = async () => {
    try {
      const emailLists =
        await appStateCtx.apiServerClient.employee.emails.getEmailLists();

      setEmailLists(emailLists);

      if (emailLists.some((e) => e.id === "myself")) {
        setEmail({
          subject: "",
          html: "",
          file: undefined,
          emailListId: "myself",
        });
      } else if (emailLists.length) {
        setEmail({
          subject: "",
          html: "",
          file: undefined,
          emailListId: emailLists[0].id,
        });
      }
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const refreshSavedEmails = async () => {
    try {
      const savedEmails =
        await appStateCtx.apiServerClient.employee.emails.getSavedEmails();

      setSavedEmails(savedEmails);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleLoadSavedEmailClicked = async (email) => {
    setEmail({ ...email });
    setShowLoadModal(false);
    messageCtx.showSuccess("Email hentet");
  };

  const handleSaveSavedEmailClicked = async (savedEmail) => {
    try {
      const email2 =
        await appStateCtx.apiServerClient.employee.emails.updateSavedEmail(
          savedEmail._id,
          email.emailListId,
          email.subject,
          email.html,
          email.file
        );
      const index = savedEmails.indexOf(savedEmail);

      const newSavedEmails = [...savedEmails];
      newSavedEmails.splice(index, 1, email2);
      setSavedEmails(newSavedEmails);
      setShowSaveModal(false);
      messageCtx.showSuccess("Email gemt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleSaveNewClicked = async () => {
    try {
      const email2 =
        await appStateCtx.apiServerClient.employee.emails.insertSavedEmail(
          newName,
          email.emailListId,
          email.subject,
          email.html,
          email.file
        );
      setSavedEmails([...savedEmails, email2]);
      setShowSaveModal(false);
      messageCtx.showSuccess("Email gemt");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  useEffect(() => {
    refresh();
    refreshSavedEmails();
    //eslint-disable-next-line
  }, []);

  const handleSendClicked = async () => {
    try {
      await appStateCtx.apiServerClient.employee.emails.send(
        email.emailListId,
        email.subject,
        sanitizeHtml(email.html),
        email.file
      );
      setEmail({ ...email, subject: "", html: "", file: undefined });
      messageCtx.showSuccess(
        "Job sat i kø og vil blive processeret hurtigts muligt"
      );
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleLoadClicked = () => {
    setShowLoadModal(true);
  };

  const handleSaveClicked = () => {
    setShowSaveModal(true);
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(email.subject)) return false;
    if (!sanitizeHtml(email.html)) return false;
    if (!validateUtil.string.isValid(email.emailListId)) return false;

    return true;
  };

  const handleDeleteSavedEmailClicked = async (e, savedEmail) => {
    e.stopPropagation();

    try {
      await appStateCtx.apiServerClient.employee.emails.deleteSavedEmail(
        savedEmail._id
      );
      messageCtx.showSuccess("Gemt email slettet");
      refreshSavedEmails();
      setShowLoadModal(false);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const isSaveEmailFormOk = () => {
    if (!validateUtil.string.isValid(newName, true, 1)) return false;
    return true;
  };

  if (!email) return null;

  return (
    <div className="container page">
      <h1>Send email</h1>
      <div className="mt-4">
        <button className="btn btn-primary" onClick={handleLoadClicked}>
          Hent
        </button>
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Email liste</label>
        <SelectInput
          items={emailLists}
          nameExtractor={(g) => g.name}
          valueExtractor={(g) => g.id}
          value={email.emailListId}
          onChange={(value) => setEmail({ ...email, emailListId: value })}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Emne</label>
        <TextInput
          value={email.subject}
          onChange={(value) => setEmail({ ...email, subject: value })}
        />
      </div>
      <div className="form-group mt-3">
        <label className="form-label">Meddelelse</label>
        <HtmlInput
          value={email.html}
          onChange={(value) => setEmail({ ...email, html: value })}
          type="email"
        />
      </div>

      <div className="form-group mt-3">
        <label className="form-label">Vedhæftning</label>
        <FileInput
          accept=".png, .jpeg, .jpg, .pdf"
          value={email.file}
          showSeeImageButton={true}
          allowEmpty={true}
          onChange={(value) => setEmail({ ...email, file: value })}
        />
      </div>
      <div className="space-children mt-3">
        <button
          className="btn btn-primary"
          onClick={handleSendClicked}
          disabled={!isFormOk()}
        >
          Send
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSaveClicked}
          disabled={!isFormOk()}
        >
          Gem
        </button>
      </div>
      <Modal show={showLoadModal} onHide={() => setShowLoadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Hent email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {savedEmails.length > 0 ? (
            <TableControl
              columns={[
                { header: "Navn", valueExtractor: (e) => e.name },
                {
                  header: "",
                  valueExtractor: (email2) => (
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      color="red"
                      onClick={(e) => handleDeleteSavedEmailClicked(e, email2)}
                    />
                  ),
                },
              ]}
              rows={savedEmails}
              keyExtractor={(e) => e._id}
              onRowClicked={handleLoadSavedEmailClicked}
            />
          ) : (
            <div style={{ fontStyle: "italic" }}>Der er ingen gemte emails</div>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showSaveModal} onHide={() => setShowSaveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Gem email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <label className="form-label">Gem som...</label>
              <RadiosInput
                items={["Ny", "Eksisterende"]}
                nameExtractor={(i) => i}
                valueExtractor={(i) => i}
                value={saveAsNew ? "Ny" : "Eksisterende"}
                onChange={(value) => setSaveAsNew(value === "Ny")}
              />
            </div>
            {saveAsNew && (
              <div>
                <div className="form-group mt-3">
                  <label className="form-label">Navn</label>
                  <TextInput
                    value={newName}
                    onChange={(value) => setNewName(value)}
                  />
                </div>
              </div>
            )}
            {!saveAsNew && (
              <div className="mt-3">
                {savedEmails.length > 0 ? (
                  <TableControl
                    columns={[
                      {
                        header: "Navn",
                        type: "string",
                        valueExtractor: (e) => e.name,
                      },
                    ]}
                    rows={savedEmails}
                    keyExtractor={(e) => e._id}
                    onRowClicked={handleSaveSavedEmailClicked}
                  />
                ) : (
                  <div style={{ fontStyle: "italic" }}>
                    Der er ingen gemte emails
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        {saveAsNew && (
          <Modal.Footer>
            <button
              className="btn btn-primary"
              disabled={!isSaveEmailFormOk()}
              onClick={handleSaveNewClicked}
            >
              Gem
            </button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default SendPage;
