const serverUrl = "/api/v1"; // http://localhost:3000

export class ApiServerError extends Error {
  isServerError = true;

  constructor(message) {
    super(message);
  }
}

class ApiServerClient {
  #token = undefined;

  #checkStatus = async (res) => {
    if (res.ok) {
      // res.status >= 200 && res.status < 300
      const json = await res.json();
      return json;
    } else if (res.status === 500) {
      const json = await res.json();
      throw new ApiServerError(json.error);
    } else {
      throw new Error(res.statusText);
    }
  };

  #post = async (path, authorization, data) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authorization) {
      //options.headers.Authorization = "Bearer " + this.#token;
      options.headers.Capp2Authorization = "Bearer " + this.#token;
    }

    if (data) {
      options.body = JSON.stringify(data);
    }

    const endpointUrl = `${serverUrl}${path}`;

    //console.log(endpointUrl);

    const res = await fetch(endpointUrl, options);

    const result = await this.#checkStatus(res);

    //console.log("result", result);

    return result;
  };

  #postMultiparts = async (path, authorization, fileDatas, data) => {
    const options = {
      method: "POST",
      headers: {},
    };

    if (authorization) {
      //options.headers.Authorization = "Bearer " + this.#token;
      options.headers.Capp2Authorization = "Bearer " + this.#token;
    }

    const payload = new FormData();

    if (data) {
      payload.append("data", JSON.stringify(data));
    }

    for (var fileData of fileDatas) {
      payload.append(fileData.type, fileData.file);
    }

    options.body = payload;

    const res = await fetch(`${serverUrl}${path}`, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  #postMultipart = async (path, authorization, file, data) => {
    const options = {
      method: "POST",
      headers: {},
    };

    if (authorization) {
      //options.headers.Authorization = "Bearer " + this.#token;
      options.headers.Capp2Authorization = "Bearer " + this.#token;
    }

    const payload = new FormData();

    if (data) {
      payload.append("data", JSON.stringify(data));
    }

    if (file) {
      payload.append("file", file);
    }

    options.body = payload;

    const res = await fetch(`${serverUrl}${path}`, options);

    const result = await this.#checkStatus(res);

    return result;
  };

  visitor = {
    announcements: {
      getAll: async () => {
        const announcements = await this.#post(
          "/visitor/announcements/getAll",
          true
        );

        return announcements;
      },
      getById: async (announcementId) => {
        const announcement = await this.#post(
          "/visitor/announcements/getById",
          true,
          {
            announcementId,
          }
        );

        return announcement;
      },
    },
    contact: {
      send: async (company, name, email, phone, about) => {
        await this.#post("/visitor/contact/send", true, {
          company,
          name,
          email,
          phone,
          about,
        });
      },
    },
    employees: {
      getAll: async () => {
        const employees = await this.#post("/visitor/employees/getAll", true);

        return employees;
      },
    },
    newsletterSubscribers: {
      subscribe: async (companyName, name, email, phone) => {
        await this.#post("/visitor/newsletterSubscribers/subscribe", false, {
          companyName,
          name,
          email,
          phone,
        });
      },
      getById: async (id) => {
        const subscriber = await this.#post(
          "/visitor/newsletterSubscribers/getById",
          false,
          {
            id,
          }
        );

        return subscriber;
      },
      unsubscribe: async (id) => {
        await this.#post("/visitor/newsletterSubscribers/unsubscribe", false, {
          id,
        });
      },
    },
    references: {
      getAll: async () => {
        const references = await this.#post("/visitor/references/getAll", true);

        return references;
      },
    },
    banners: {
      getAll: async () => {
        const references = await this.#post("/visitor/banners/getAll", true);

        return references;
      },
    },
    products: {
      getAllWithDataSheet: async () => {
        const products = await this.#post(
          "/visitor/products/getAllWithDataSheet",
          true
        );

        return products;
      },
      getByName: async (productGroupName, productName) => {
        const product = await this.#post("/visitor/products/getByName", true, {
          productGroupName,
          productName,
        });

        return product;
      },
      getAll: async (skipDoNotShowOnHomepage) => {
        const products = await this.#post("/visitor/products/getAll", true, {
          skipDoNotShowOnHomepage,
        });

        return products;
      },
      getByProductGroupName: async (productGroupName) => {
        const products = await this.#post(
          "/visitor/products/getByProductGroupName",
          true,
          {
            productGroupName,
          }
        );

        return products;
      },
      getAllFocusProducts: async () => {
        const products = await this.#post(
          "/visitor/products/getAllFocusProducts",
          true
        );

        return products;
      },
    },
    productReviews: {
      getByProductId: async (productId) => {
        const reviews = await this.#post(
          "/visitor/productReviews/getByProductId",
          true,
          {
            productId,
          }
        );

        return reviews;
      },
    },
    productGroups: {
      getAll: async () => {
        const groups = await this.#post("/visitor/productGroups/getAll", true);

        return groups;
      },
    },
    settings: {
      get: async () => {
        const settings = await this.#post("/visitor/settings/get", false);

        return settings;
      },
    },
    videos: {
      getAll: async () => {
        const videos = await this.#post("/visitor/videos/getAll", true);

        return videos;
      },
    },
  };

  customer = {
    calenderEvents: {
      getMeetingById: async (calenderEventId) => {
        const event = await this.#post(
          "/customer/calenderEvents/getMeetingById",
          true,
          {
            calenderEventId,
          }
        );

        return event;
      },
      getMeetings: async () => {
        const events = await this.#post(
          "/customer/calenderEvents/getMeetings",
          true
        );

        return events;
      },
    },
    customers: {
      insertCustomerContactFavorite: async (productGroupName, productName) => {
        const favorite = await this.#post(
          "/customer/customers/insertCustomerContactFavorite",
          true,
          {
            productGroupName,
            productName,
          }
        );
        return favorite;
      },
      deleteCustomerContactFavorite: async (productId) => {
        await this.#post(
          "/customer/customers/deleteCustomerContactFavorite",
          true,
          {
            productId,
          }
        );
      },
      changePassword: async (password) => {
        await this.#post("/customer/customers/changePassword", true, {
          password,
        });
      },
    },
    invoices: {
      getAll: async () => {
        const invoices = await this.#post("/customer/invoices/getAll", true);

        return invoices;
      },
      getByBookedInvoiceNumber: async (bookedInvoiceNumber) => {
        const invoice = await this.#post(
          "/customer/invoices/getByBookedInvoiceNumber",
          true,
          {
            bookedInvoiceNumber,
          }
        );

        return invoice;
      },
      getLineProducts: async () => {
        const lineProducts = await this.#post(
          "/customer/invoices/getLineProducts",
          true
        );

        return lineProducts;
      },
    },
    customerOrders: {
      create: async (order) => {
        await this.#post("/customer/customerOrders/create", true, {
          order,
        });
      },
    },
  };

  employee = {
    announcements: {
      getAll: async () => {
        const announcements = await this.#post(
          "/employee/announcements/getAll",
          true
        );

        return announcements;
      },
      getById: async (announcementId) => {
        const announcement = await this.#post(
          "/employee/announcements/getById",
          true,
          {
            announcementId,
          }
        );

        return announcement;
      },
      insert: async (banner, title, text, frontpagetext, enabled) => {
        const announcement = await this.#postMultipart(
          "/employee/announcements/insert",
          true,
          banner,
          {
            title,
            text,
            frontpagetext,
            enabled,
          }
        );

        return announcement;
      },
      update: async (
        announcementId,
        banner,
        title,
        text,
        frontpagetext,
        enabled
      ) => {
        await this.#postMultipart(
          "/employee/announcements/update",
          true,
          banner,
          {
            announcementId,
            title,
            text,
            frontpagetext,
            enabled,
          }
        );
      },
      delete: async (announcementId) => {
        await this.#post("/employee/announcements/delete", true, {
          announcementId,
        });
      },
    },
    banners: {
      getAll: async () => {
        const banners = await this.#post("/employee/banners/getAll", true);

        return banners;
      },
      getById: async (bannerId) => {
        const banner = await this.#post("/employee/banners/getById", true, {
          bannerId,
        });

        return banner;
      },
      insert: async (name, title, subTitle, image, showButtons, enabled) => {
        const event = await this.#postMultipart(
          "/employee/banners/insert",
          true,
          image,
          {
            name,
            title,
            subTitle,
            showButtons,
            enabled,
          }
        );

        return event;
      },
      update: async (
        bannerId,
        name,
        title,
        subTitle,
        image,
        showButtons,
        enabled
      ) => {
        await this.#postMultipart("/employee/banners/update", true, image, {
          bannerId,
          name,
          title,
          subTitle,
          showButtons,
          enabled,
        });
      },
      delete: async (bannerId) => {
        await this.#post("/employee/banners/delete", true, {
          bannerId,
        });
      },
    },
    calenderEvents: {
      getTypes: async () => {
        const types = await this.#post(
          "/employee/calenderEvents/getTypes",
          true
        );

        return types;
      },
      getByMonth: async (month) => {
        const events = await this.#post(
          "/employee/calenderEvents/getByMonth",
          true,
          {
            month,
          }
        );

        return events;
      },
      getCalenders: async () => {
        return await this.#post("/employee/calenderEvents/getCalenders", true);
      },
      getByCustomerId: async (customerId) => {
        const events = await this.#post(
          "/employee/calenderEvents/getByCustomerId",
          true,
          {
            customerId,
          }
        );

        return events;
      },
      getById: async (calenderEventId) => {
        const event = await this.#post(
          "/employee/calenderEvents/getById",
          true,
          {
            calenderEventId,
          }
        );

        return event;
      },
      insert: async (
        employeeId,
        header,
        startDate,
        startTime,
        endDate,
        endTime,
        typeId,
        customerId,
        contactName,
        contactEmail,
        contactPhone,
        place,
        note,
        important
      ) => {
        const event = await this.#post(
          "/employee/calenderEvents/insert",
          true,
          {
            employeeId,
            header,
            startDate,
            startTime,
            endDate,
            endTime,
            typeId,
            customerId,
            contactName,
            contactEmail,
            contactPhone,
            place,
            note,
            important,
          }
        );

        return event;
      },
      update: async (
        calenderEventId,
        employeeId,
        header,
        startDate,
        startTime,
        endDate,
        endTime,
        typeId,
        customerId,
        contactName,
        contactEmail,
        contactPhone,
        place,
        note,
        important
      ) => {
        await this.#post("/employee/calenderEvents/update", true, {
          calenderEventId,
          employeeId,
          header,
          startDate,
          startTime,
          endDate,
          endTime,
          typeId,
          customerId,
          contactName,
          contactEmail,
          contactPhone,
          place,
          note,
          important,
        });
      },
      delete: async (calenderEventId) => {
        await this.#post("/employee/calenderEvents/delete", true, {
          calenderEventId,
        });
      },
      updateFinished: async (calenderEventId, finished) => {
        await this.#post("/employee/calenderEvents/updateFinished", true, {
          calenderEventId,
          finished,
        });
      },
      sendMeetingConfirmationEmail: async (calenderEventId) => {
        const event = await this.#post(
          "/employee/calenderEvents/sendMeetingConfirmationEmail",
          true,
          {
            calenderEventId,
          }
        );

        return event;
      },
    },
    customers: {
      getReportTypes: async () => {
        const types = await this.#post(
          "/employee/customers/getReportTypes",
          true
        );

        return types;
      },
      generateReport: async (typeId, customerNumber, query) => {
        const report = await this.#post(
          "/employee/customers/generateReport",
          true,
          {
            typeId,
            customerNumber,
            query,
          }
        );

        return report;
      },
      getAll: async () => {
        const customers = await this.#post("/employee/customers/getAll", true);

        return customers;
      },
      getByNumber: async (customerNumber) => {
        const customer = await this.#post(
          "/employee/customers/getByNumber",
          true,
          {
            customerNumber,
          }
        );

        return customer;
      },
      getByProductName: async (productGroupName, productName) => {
        const customers = await this.#post(
          "/employee/customers/getByProductName",
          true,
          {
            productGroupName,
            productName,
          }
        );

        return customers;
      },
      update: async (customerNumber, contacts) => {
        await this.#post("/employee/customers/update", true, {
          customerNumber,
          contacts,
        });
      },
      updateGeneralNote: async (
        customerNumber,
        buyer,
        employees,
        departments,
        employeeCount,
        typeOfWork,
        providers
      ) => {
        await this.#post("/employee/customers/updateGeneralNote", true, {
          customerNumber,
          buyer,
          employees,
          departments,
          employeeCount,
          typeOfWork,
          providers,
        });
      },
      sendContactPassword: async (customerNumber, customerContactNumber) => {
        await this.#post("/employee/customers/sendContactPassword", true, {
          customerNumber,
          customerContactNumber,
        });
      },
    },
    customerGroups: {
      getAll: async () => {
        const groups = await this.#post(
          "/employee/customerGroups/getAll",
          true
        );

        return groups;
      },
    },
    customerNotes: {
      getByCustomerId: async (customerId) => {
        const notes = await this.#post(
          "/employee/customerNotes/getByCustomerId",
          true,
          {
            customerId,
          }
        );

        return notes;
      },
      getById: async (customerNoteId) => {
        const note = await this.#post("/employee/customerNotes/getById", true, {
          customerNoteId,
        });

        return note;
      },
      updateFinished: async (customerNoteId, finished) => {
        await this.#post("/employee/customerNotes/updateFinished", true, {
          customerNoteId,
          finished,
        });
      },
      insert: async (customerId, text, file) => {
        const event = await this.#postMultipart(
          "/employee/customerNotes/insert",
          true,
          file,
          {
            text,
            customerId,
          }
        );

        return event;
      },
      update: async (customerNoteId, text, file) => {
        await this.#postMultipart(
          "/employee/customerNotes/update",
          true,
          file,
          {
            customerNoteId,
            text,
            fileId: file?._id,
          }
        );
      },
      delete: async (customerNoteId) => {
        await this.#post("/employee/customerNotes/delete", true, {
          customerNoteId,
        });
      },
    },
    employees: {
      getReportTypes: async () => {
        const types = await this.#post(
          "/employee/employees/getReportTypes",
          true
        );

        return types;
      },
      generateReport: async (typeId, startDate, endDate, employeeNumber) => {
        const report = await this.#post(
          "/employee/employees/generateReport",
          true,
          {
            typeId,
            startDate,
            endDate,
            employeeNumber,
          }
        );

        return report;
      },
      getAll: async () => {
        const employees = await this.#post("/employee/employees/getAll", true);

        return employees;
      },
      getRoles: async () => {
        const roles = await this.#post("/employee/employees/getRoles", true);

        return roles;
      },
      getByNumber: async (employeeNumber) => {
        const employee = await this.#post(
          "/employee/employees/getByNumber",
          true,
          {
            employeeNumber,
          }
        );

        return employee;
      },
      sendNewPassword: async (employeeId) => {
        await this.#post("/employee/employees/sendNewPassword", true, {
          employeeId,
        });
      },
      changePassword: async (password) => {
        await this.#post("/employee/employees/changePassword", true, {
          password,
        });
      },
      update: async (
        employeeId,
        position,
        customerGroups,
        roleIds,
        calenders,
        image
      ) => {
        const employee = await this.#postMultipart(
          "/employee/employees/update",
          true,
          image,
          {
            employeeId,
            position,
            customerGroups,
            roleIds,
            calenders,
            imageId: image?._id,
          }
        );

        return employee;
      },
    },
    emailTemplates: {
      getAll: async () => {
        const emails = await this.#post(
          "/employee/emailTemplates/getAll",
          true
        );

        return emails;
      },
      getById: async (emailTemplateId) => {
        const email = await this.#post(
          "/employee/emailTemplates/getById",
          true,
          {
            emailTemplateId,
          }
        );

        return email;
      },
      sendTest: async (type) => {
        await this.#post("/employee/emailTemplates/sendTest", true, {
          type,
        });
      },
      update: async (emailTemplateId, enabled, subject, body) => {
        const email = await this.#post(
          "/employee/emailTemplates/update",
          true,
          {
            emailTemplateId,
            enabled,
            subject,
            body,
          }
        );

        return email;
      },
    },
    emails: {
      getEmailLists: async () => {
        const groups = await this.#post("/employee/email/getEmailLists", true);

        return groups;
      },
      send: async (emailListId, subject, html, file) => {
        const data = {
          emailListId,
          subject,
          html,
        };

        if (!(file instanceof File)) {
          data.file = file;
        }

        await this.#postMultipart("/employee/email/send", true, file, data);
      },
      getSavedEmails: async () => {
        const emails = await this.#post("/employee/email/getSavedEmails", true);
        return emails;
      },
      getSavedEmailById: async (savedEmailId) => {
        const email = await this.#post(
          "/employee/email/getSavedEmailById",
          true,
          {
            savedEmailId,
          }
        );
        return email;
      },
      insertSavedEmail: async (name, emailListId, subject, html, file) => {
        const data = {
          name,
          emailListId,
          subject,
          html,
        };

        let file2;

        if (file instanceof File) {
          file2 = file;
        } else {
          data.file = file;
        }

        const email = await this.#postMultipart(
          "/employee/email/insertSavedEmail",
          true,
          file2,
          data
        );

        return email;
      },
      updateSavedEmail: async (
        savedEmailId,
        emailListId,
        subject,
        html,
        file
      ) => {
        const data = {
          savedEmailId,
          emailListId,
          subject,
          html,
        };

        let file2;

        if (file instanceof File) {
          file2 = file;
        } else {
          data.file = file;
        }

        const email = await this.#postMultipart(
          "/employee/email/updateSavedEmail",
          true,
          file2,
          data
        );
        return email;
      },
      deleteSavedEmail: async (savedEmailId) => {
        await this.#post("/employee/email/deleteSavedEmail", true, {
          savedEmailId,
        });
      },
    },
    invoices: {
      getByMonth: async (month) => {
        const invoices = await this.#post(
          "/employee/invoices/getByMonth",
          true,
          {
            month,
          }
        );

        return invoices;
      },
      getByBookedInvoiceNumber: async (bookedInvoiceNumber) => {
        const invoice = await this.#post(
          "/employee/invoices/getByBookedInvoiceNumber",
          true,
          {
            bookedInvoiceNumber,
          }
        );

        return invoice;
      },
      getByCustomerNumber: async (customerNumber) => {
        const invoices = await this.#post(
          "/employee/invoices/getByCustomerNumber",
          true,
          {
            customerNumber,
          }
        );

        return invoices;
      },
      getByProductName: async (productGroupName, productName) => {
        const invoices = await this.#post(
          "/employee/invoices/getByProductName",
          true,
          {
            productGroupName,
            productName,
          }
        );

        return invoices;
      },
    },
    jobs: {
      getById: async (jobId) => {
        const job = await this.#post("/employee/jobs/getById", true, {
          jobId,
        });

        return job;
      },
      getLatest2: async (amount) => {
        const jobs = await this.#post("/employee/jobs/getLatest2", true, {
          amount,
        });

        return jobs;
      },
      getLatest: async (amount) => {
        const jobs = await this.#post("/employee/jobs/getLatest", true, {
          amount,
        });

        return jobs;
      },
      runNowById: async (jobId) => {
        const job = await this.#post("/employee/jobs/runNowById", true, {
          jobId,
        });

        return job;
      },
      update: async (jobId, enabled) => {
        const job = await this.#post("/employee/jobs/updateById", true, {
          jobId,
          enabled,
        });

        return job;
      },
    },
    newsletterSubscribers: {
      getAll: async () => {
        const subscribers = await this.#post(
          "/employee/newsletterSubscribers/getAll",
          true
        );

        return subscribers;
      },
      getById: async (subscriberId) => {
        const subscriber = await this.#post(
          "/employee/newsletterSubscribers/getById",
          true,
          { subscriberId }
        );

        return subscriber;
      },
      delete: async (subscriberId) => {
        const subscriber = await this.#post(
          "/employee/newsletterSubscribers/delete",
          true,
          { subscriberId }
        );

        return subscriber;
      },
    },
    employeeOrders: {
      getSaved: async () => {
        const orders = await this.#post(
          "/employee/employeeOrders/getSaved",
          true
        );

        return orders;
      },
      save: async (order) => {
        const order2 = await this.#post("/employee/employeeOrders/save", true, {
          order,
        });

        return order2;
      },
      load: async (orderId) => {
        const order = await this.#post("/employee/employeeOrders/load", true, {
          orderId,
        });

        return order;
      },
      delete: async (orderId) => {
        await this.#post("/employee/employeeOrders/delete", true, {
          orderId,
        });
      },
      getByMonth: async (month) => {
        const orders = await this.#post(
          "/employee/employeeOrders/getByMonth",
          true,
          {
            month,
          }
        );

        return orders;
      },
      getByProcessed: async (processed) => {
        const orders = await this.#post(
          "/employee/employeeOrders/getByProcessed",
          true,
          {
            processed,
          }
        );

        return orders;
      },
      getById: async (orderId) => {
        const order = await this.#post(
          "/employee/employeeOrders/getById",
          true,
          {
            orderId,
          }
        );

        return order;
      },
      create: async (order) => {
        await this.#post("/employee/employeeOrders/create", true, {
          order,
        });
      },
      updateProcessed: async (orderId, processed) => {
        const order = await this.#post(
          "/employee/employeeOrders/updateProcessed",
          true,
          {
            orderId,
            processed,
          }
        );

        return order;
      },
    },
    customerOrders: {
      getByProcessed: async (processed) => {
        const orders = await this.#post(
          "/employee/customerOrders/getByProcessed",
          true,
          {
            processed,
          }
        );

        return orders;
      },
      getById: async (orderId) => {
        const order = await this.#post(
          "/employee/customerOrders/getById",
          true,
          {
            orderId,
          }
        );

        return order;
      },

      updateProcessed: async (orderId, processed) => {
        const order = await this.#post(
          "/employee/customerOrders/updateProcessed",
          true,
          {
            orderId,
            processed,
          }
        );

        return order;
      },
    },
    products: {
      getOverlayTypes: async () => {
        const overlayTypes = await this.#post(
          "/employee/products/getOverlayTypes",
          true
        );

        return overlayTypes;
      },
      getOverlayPositions: async () => {
        const overlayPositions = await this.#post(
          "/employee/products/getOverlayPositions",
          true
        );

        return overlayPositions;
      },
      getReportTypes: async () => {
        const types = await this.#post(
          "/employee/products/getReportTypes",
          true
        );

        return types;
      },
      generateReport: async (typeId, productGroupName, productName) => {
        const report = await this.#post(
          "/employee/products/generateReport",
          true,
          {
            typeId,
            productGroupName,
            productName,
          }
        );

        return report;
      },
      getAll: async (
        includeBarred,
        includeDoNotShowOnHomepage,
        includeNoSubProducts
      ) => {
        const products = await this.#post("/employee/products/getAll", true, {
          includeBarred,
          includeDoNotShowOnHomepage,
          includeNoSubProducts,
        });

        return products;
      },
      update: async (
        productId,
        description,
        tags,
        overlay1TypeId,
        overlay1PositionId,
        overlay2TypeId,
        overlay2PositionId,
        medias,
        documents,
        subProducts
      ) => {
        const mediaDatas = medias
          .filter((m) => m.file instanceof File)
          .map((m) => ({ type: "mediaFiles", file: m.file }));

        let i = 0;
        const medias2 = medias.map((m) =>
          m.file instanceof File ? { index: i++ } : m
        );

        const documentDatas = documents
          .filter((d) => d.file instanceof File)
          .map((d) => ({ type: "documentFiles", file: d.file }));

        i = 0;
        const documents2 = documents.map((d) =>
          d.file instanceof File ? { index: i++, type: d.type } : d
        );

        await this.#postMultiparts(
          "/employee/products/update",
          true,
          [...mediaDatas, ...documentDatas],
          {
            productId,
            description,
            tags,
            overlay1TypeId,
            overlay1PositionId,
            overlay2TypeId,
            overlay2PositionId,
            medias: medias2,
            documents: documents2,
            subProducts,
          }
        );
      },
      getByProductGroupName: async (productGroupName) => {
        const products = await this.#post(
          "/employee/products/getByProductGroupName",
          true,
          {
            productGroupName,
          }
        );

        return products;
      },
      getByName: async (productGroupName, productName) => {
        const product = await this.#post("/employee/products/getByName", true, {
          productGroupName,
          productName,
        });

        return product;
      },
      getById: async (productId) => {
        const product = await this.#post("/employee/products/getById", true, {
          productId,
        });

        return product;
      },
      getAllFocusProducts: async () => {
        const products = await this.#post(
          "/employee/products/getAllFocusProducts",
          true
        );

        return products;
      },
      insertOrUpdateFocusProduct: async (
        productGroupName,
        productName,
        text,
        reason,
        enabled
      ) => {
        await this.#post(
          "/employee/products/insertOrUpdateFocusProduct",
          true,
          {
            productGroupName,
            productName,
            text,
            reason,
            enabled,
          }
        );
      },
      deleteFocusProduct: async (productGroupName, productName) => {
        await this.#post("/employee/products/deleteFocusProduct", true, {
          productGroupName,
          productName,
        });
      },
    },
    productReviews: {
      getByProductId: async (productId) => {
        const reviews = await this.#post(
          "/employee/productReviews/getByProductId",
          true,
          {
            productId,
          }
        );

        return reviews;
      },
      getById: async (productReviewId) => {
        const review = await this.#post(
          "/employee/productReviews/getById",
          true,
          {
            productReviewId,
          }
        );

        return review;
      },
      insert: async (productId, title, text, reviewer) => {
        const review = await this.#post(
          "/employee/productReviews/insert",
          true,
          {
            // productGroupName,
            // productName,
            productId,
            title,
            text,
            reviewer,
          }
        );

        return review;
      },
      update: async (productReviewId, title, text, reviewer) => {
        await this.#post("/employee/productReviews/update", true, {
          productReviewId,
          title,
          text,
          reviewer,
        });
      },
      delete: async (productReviewId) => {
        await this.#post("/employee/productReviews/delete", true, {
          productReviewId,
        });
      },
    },
    productGroups: {
      getAll: async () => {
        const groups = await this.#post("/employee/productGroups/getAll", true);

        return groups;
      },
      getByName: async (productGroupName) => {
        const group = await this.#post(
          "/employee/productGroups/getByName",
          true,
          {
            productGroupName,
          }
        );

        return group;
      },
      update: async (productGroupId, image) => {
        const group = await this.#postMultipart(
          "/employee/productGroups/update",
          true,
          image,
          { productGroupId, imageId: image?._id }
        );

        return group;
      },
    },
    qrCodes: {
      getAll: async () => {
        const banners = await this.#post("/employee/qrCodes/getAll", true);

        return banners;
      },
      getById: async (qrCodeId) => {
        const banner = await this.#post("/employee/qrCodes/getById", true, {
          qrCodeId,
        });

        return banner;
      },
      insert: async (description, productGroupName, productName, document) => {
        const code = await this.#post("/employee/qrCodes/insert", true, {
          description,
          productGroupName,
          productName,
          document,
        });

        return code;
      },
    },
    reportEvents: {
      getTypes: async () => {
        const events = await this.#post(
          "/employee/reportEvents/getTypes",
          true
        );

        return events;
      },
      getVisitTypes: async () => {
        const events = await this.#post(
          "/employee/reportEvents/getVisitTypes",
          true
        );

        return events;
      },
      getByDate: async (date) => {
        const events = await this.#post(
          "/employee/reportEvents/getByDate",
          true,
          {
            date,
          }
        );

        return events;
      },
      getById: async (reportEventId) => {
        const event = await this.#post("/employee/reportEvents/getById", true, {
          reportEventId,
        });

        return event;
      },
      insert: async (
        date,
        type,
        demo,
        visitType,
        description,
        street,
        zip,
        km,
        city,
        coordinate,
        unlockAddress,
        revenue
      ) => {
        const event = await this.#post("/employee/reportEvents/insert", true, {
          date,
          type,
          demo,
          visitType,
          description,
          street,
          zip,
          km,
          city,
          coordinate,
          unlockAddress,
          revenue,
        });

        return event;
      },
      update: async (
        reportEventId,
        date,
        type,
        demo,
        visitType,
        description,
        street,
        zip,
        km,
        city,
        coordinate,
        unlockAddress,
        revenue
      ) => {
        const event = await this.#post("/employee/reportEvents/update", true, {
          reportEventId,
          date,
          type,
          demo,
          visitType,
          description,
          street,
          zip,
          km,
          city,
          coordinate,
          unlockAddress,
          revenue,
        });

        return event;
      },
      delete: async (reportEventId) => {
        await this.#post("/employee/reportEvents/delete", true, {
          reportEventId,
        });
      },
    },
    references: {
      getAll: async () => {
        const references = await this.#post(
          "/employee/references/getAll",
          true
        );

        return references;
      },
      getById: async (referenceId) => {
        const reference = await this.#post(
          "/employee/references/getById",
          true,
          {
            referenceId,
          }
        );

        return reference;
      },
      insert: async (title, text, from, type, enabled) => {
        const event = await this.#post("/employee/references/insert", true, {
          title,
          text,
          from,
          type,
          enabled,
        });

        return event;
      },
      update: async (referenceId, title, text, from, type, enabled) => {
        await this.#post("/employee/references/update", true, {
          referenceId,
          title,
          text,
          from,
          type,
          enabled,
        });
      },
      delete: async (referenceId) => {
        await this.#post("/employee/references/delete", true, {
          referenceId,
        });
      },
    },
    roles: {
      getPermissions: async () => {
        const permissions = await this.#post(
          "/employee/roles/getPermissions",
          true
        );

        return permissions;
      },
      getAll: async () => {
        const roles = await this.#post("/employee/roles/getAll", true);

        return roles;
      },
      getById: async (roleId) => {
        const role = await this.#post("/employee/roles/getById", true, {
          roleId,
        });

        return role;
      },
      insert: async (name, permissionIds) => {
        const role = await this.#post("/employee/roles/insert", true, {
          name,
          permissionIds,
        });

        return role;
      },
      update: async (roleId, name, permissionIds) => {
        const role = await this.#post("/employee/roles/update", true, {
          roleId,
          name,
          permissionIds,
        });

        return role;
      },
      delete: async (roleId) => {
        await this.#post("/employee/roles/delete", true, {
          roleId,
        });
      },
    },
    settings: {
      get: async () => {
        const settings = await this.#post("/employee/settings/get", true);

        return settings;
      },
      update: async (cartState) => {
        await this.#post("/employee/settings/update", true, {
          cartState,
        });
      },
      updateProductCatalog: async (productCatalog) => {
        await this.#postMultipart(
          "/employee/settings/updateProductCatalog",
          true,
          productCatalog,
          {
            removeProductCatalog: !productCatalog,
          }
        );
      },
      updateAbout: async (about) => {
        await this.#post("/employee/settings/updateAbout", true, {
          about,
        });
      },
    },
    videos: {
      getAll: async () => {
        const videos = await this.#post("/employee/videos/getAll", true);

        return videos;
      },
      getById: async (videoId) => {
        const review = await this.#post("/employee/videos/getById", true, {
          videoId,
        });

        return review;
      },
      insert: async (title, text, video, productId, enabled) => {
        const event = await this.#postMultipart(
          "/employee/videos/insert",
          true,
          video,
          {
            title,
            text,
            productId,
            enabled,
          }
        );

        return event;
      },
      update: async (videoId, title, text, video, productId, enabled) => {
        await this.#postMultipart("/employee/videos/update", true, video, {
          videoId,
          title,
          text,
          productId,
          enabled,
        });
      },
      delete: async (videoId) => {
        await this.#post("/employee/videos/delete", true, {
          videoId,
        });
      },
    },
  };

  auth = {
    loginByCredentials: async (email, password) => {
      const auth = await this.#post("/auth/loginByCredentials", false, {
        email,
        password,
      });

      this.#token = auth.token;

      return auth;
    },
    loginVisitor: async () => {
      const auth = await this.#post("/auth/loginVisitor", false);

      this.#token = auth.token;

      return auth;
    },
    loginByToken: async (token) => {
      const auth = await this.#post("/auth/loginByToken", false, {
        token,
      });

      this.#token = auth.token;

      return auth;
    },
  };
}

export default ApiServerClient;
